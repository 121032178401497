@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$primary: #0a383d;
$secondary: #4dcacf;
$dark-primary: #0e353a;
$light-primary: #235156;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.container {
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.container-fluid {
  max-width: 990px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
body, html {
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  .hamburger-menu-button {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  .header-links {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: 767px) {
    .header-links {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 280px;
      overflow: auto;
      max-height: 100vh;
      background: $dark-primary;
      color: #fff;
      transform: translateX(280px);
      transition: all 0.3s ease-in-out;
      text-align: right;
      display: block;
      z-index: 1;
      .hamburger-menu-button {
        top: 15px;
        right: 15px;
      }
  }
  header .header .header-links ul {
    flex-direction: column;
    margin-top: 25px;
    li {
      padding: 10px 15px;
      a {
        margin-right: 20px;
      }
    }
  }
  .header-links.show {
    transform: translateX(0);
  }
  }
  .hamburger-menu,
  .hamburger-menu::after {
    height: calc(0.125 * 32px);
    border-radius: calc(0.15 * 32px);
    background-color: #fff;
    /* transition: all 0.25s ease-in-out; */
  }
  
  .hamburger-menu {
    width: calc(0.75 * 32px);
    transform: translateY(calc(-0.125 * 32px));
  }
  
  .hamburger-menu::after {
    position: absolute;
    width: calc(0.375 * 32px);
    transform: translateY(calc(0.25 * 32px));
    content: '';
    right: 0;
  }
  
  .hamburger-menu.open {
    transform: translateY(0px) rotate(45deg);
  }
  
  .hamburger-menu.open::after {
    width: calc(0.75 * 32px);
    transform: translateY(0px) rotate(-90deg);
  }
  .hamburger-menu.open, .hamburger-menu.open::after {
    background-color: #fff;
  }
  header {
    background-color: $primary;
    padding: 20px;
    box-shadow: 0 1px 10px 0 #0a383d;
    position: relative;
    z-index: 1;
    &.fixed-header {
      position: fixed;
      top: -80px;
      left: 0;
      right: 0;
      animation: slide 0.6s ease-in-out forwards;
      z-index: 1;
    }
    .header {
      display: flex;
      align-items: center;
    .header-logo {
      padding-right: 30px;
    }
    ul {
      list-style-type: none;
      display: flex;
      li {
        position: relative;
        a {
          color: #fff;
          text-decoration: none;
          font-weight: 600;
          font-size: 15px;
          margin-right: 60px;
          position: relative;
          padding-right: 18px;
          @media screen and (max-width: 992px) {
            margin-right: 40px;
          }
          &:before {
            content: '';
            position: absolute;
            top: calc(50% - 3px);
            transform: translateY(-50%) rotate(45deg);
            right: 0;
            height: 8px;
            width: 8px;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
          }
          &.active::before {
            transform:translate(-50%) rotate(-135deg);
              transform-origin: 7px center;
          }
        }
        &:hover a:before {
          @media screen and (min-width: 768px) {
            transform:translate(-50%) rotate(-135deg);
            transform-origin: 7px center;
          }
        }
        .sub-menu {
          position: absolute;
          left: -30px;
          top: 100%;
          margin-top: 29px;
          background: #0e353a;
          transform: scaleY(0);
          transform-origin: top;
          transition: all 0.4s ease;
          flex-direction: column;
          padding-bottom: 10px;
          @media screen and (max-width: 767px) {
            transform: none;
            position: relative;
            top: 0;
            left: 0;
            margin-top: 0;
            max-height: 0;
            padding-bottom: 0;
            overflow: hidden;
            &.active {
              margin-top: 15px;
            }
          }
          li {
            width: 100%;
            min-width: 410px;
            @media screen and (max-width: 1220px) {
              min-width: 280px;
            }
            @media screen and (max-width: 767px) {
              min-width: auto !important;
            }
            a {
              margin-right: 0;
              padding: 10px 30px;
              display: block;
              transition: all 0.4s ease;
              font-size: 14px;
              font-weight: normal;
              line-height: 24px;
              @media screen and (max-width: 767px) {
                padding: 0;
              }
              &:hover {
                color: $secondary;
              }
              &::before, &::after {
                display: none;
              }
            }
            &:last-child {
              a {
                padding-bottom: 15px;
                @media screen and (max-width: 767px) {
                  padding-bottom: 5px;
                }
              }
            }
          }
        }
        &:last-child {
          .sub-menu {
            li {
              min-width: 270px;
            }
          }
        }
        &:hover {
          > .sub-menu {
           transform: scaleY(1); 
          }
        }
      }
      &.risika-btn {
        li {
          a {
            margin: 0;
            background: #facc89;
            padding: 8px 16px;
            display: inline-block;
            border-radius: 50px;
            color: $primary;
            border: 1px solid transparent;
            transition: all 0.4s ease;
            &::before {
              display: none;
            }
            &:hover {
              background-color: transparent;
              border: 1px solid #facc89;
              color: #facc89;
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  }
  .banner {
    background-color: #235056;
    padding-top: 10px;
    .banner-wrapper {
      display: flex;
      align-items: center;
      padding: 30px 0;
      margin: 0 -20px;
      padding-bottom: 60px;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
        padding: 10px 0;
        &.b2b-banner {
          flex-direction: column-reverse;
        }
      }
      .banner-desc {
        width: 50%;
        padding: 30px 20px;
        color: #fff;
        font-size: 16px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        p {
          margin-bottom: 20px;
          line-height: 28px;
          font-size: 18px;
          font-weight: 500;
          &:last-child {
            margin-bottom: 0;
          }
        }
        h1 {
          font-size: 39px;
          font-weight: 700;
          margin-bottom: 20px;
          @media screen and (max-width: 767px) {
            font-size: 28px;
          }
        }
        h2 {
          font-weight: 600;
          font-size: 28px;
          margin-bottom: 15px;
          line-height: 38px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
      .banner-img {
        padding: 0 20px;
        width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        &.kr-img {
          max-width: 200px;
          margin: auto;
          display: block;
        }
        img {
          width: 100%;
          @media screen and (max-width: 767px) {
            max-width: 350px;
            margin: auto;
            display: block;
          }
        }
      }
    }
  }
  .b2b-banner {
    padding: 70px 0;
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }
    .banner-desc {
      h2 {
        font-size: 32px;
        text-align: center;
        color: #212429;
        margin-bottom: 50px;
        @media screen and (max-width: 767px) {
          font-size: 24px;
          padding: 0 20px;
          margin-bottom: 30px;
        }
      }
      img {
        margin: auto;
        display: block;
        width: 100%;
      }
      .b2b-text {
        display: flex;
        margin-top: 50px;
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
          padding: 0 20px;
          margin-top: 30px;
        }
        p {
          width: 50%;
          line-height: 26px;
          padding-top: 20px;
          font-weight: 500;
          &:first-child {
            padding-right: 30px;
            @media screen and (max-width: 767px) {
              padding-top: 0;
            }
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
  .why-score {
    padding-top: 10px;
    color: #000;
    background-color: #f5fbfb;
    .why-score-wrapper {
      display: flex;
      align-items: center;
      padding: 70px 0;
      margin: 0 -20px;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
        padding: 0;
      }
      .why-score-desc {
        width: 100%;
        padding: 30px 20px;
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
        .virksomheder-left, .virksomheder-right {
          width: 50%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          a {
            background-color: $primary;
            color: #fff;
            padding: 10px 20px;
            border-radius: 50px;
            font-weight: 700;
            text-decoration: none;
            display: inline-block;
            margin-top: 15px;
            border: 1px solid transparent;
            transition: all 0.4s ease;
            @media screen and (max-width: 767px) {
              margin-top: 0;
            }
            &:hover {
              background-color: transparent;
              border: 1px solid $primary;
              color: $primary;
            }
          }
        }
        .virksomheder-left {
          padding-right: 40px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        p {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          &:last-child {
            margin-bottom: 0;
          }
        }
        h2 {
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 30px;
          width: 50%;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
      .why-score-img {
        padding: 0;
        width: 50%;
        max-width: 200px;
        margin: auto;
        margin-top: -175px;
        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 20px;
          margin-left: 0;
        }
        &.kr-img {
          max-width: 300px;
          margin: auto;
          display: block;
        }
        img {
          width: 100%;
          @media screen and (max-width: 767px) {
            width: 200px;
          }
        }
      }
    }
  }
  .ten-tips {
    padding-top: 10px;
    color: #000;
    .ten-tips-wrapper {
      display: flex;
      align-items: center;
      padding: 60px 0;
      margin: 0 -20px;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
        padding: 20px 0;
      }
      .ten-tips-desc {
        width: 50%;
        padding: 0 20px;
        font-size: 16px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        p {
          margin-bottom: 20px;
          line-height: 24px;
          font-weight: 500;
          &:last-child {
            margin-bottom: 0;
          }
        }
        h2 {
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
          }
        }
      }
      .ten-tips-img {
        padding: 0 20px;
        padding-left: 40px;
        width: 50%;
        img {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 20px;
        }
        p {
          color: $secondary;
          font-weight: 500;
          font-size: 18px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .tips-step {
    color: #000;
    .tip-step-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 900px;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
      }
      .tip-step-item {
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        .tip-step-box {
          font-size: 16px;
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          line-height: 21px;
          @media screen and (max-width: 767px) {
            padding: 0 20px;
          }
          .tip-step-header {
            display: flex;
            align-items: center;
            color: #000;
            margin: 0;
            padding: 20px;
            padding-right: 50px;
            background: transparent;
            cursor: pointer;
            position: relative;
            border-bottom: 2px solid #f7fbfc;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
              right: 20px;
              height: 10px;
              width: 10px;
              border-bottom: 2px solid #000;
              border-right: 2px solid #000;
              transition: all 0.4s ease;
            }
            &.active::before {
              transform: translateY(-50%) rotate(-135deg);
            }
            &:hover, &.active {
              background-color: #f7fbfc;
            }
            span {
              font-weight: 500;
              font-size: 20px;
              line-height: 26px;
              @media screen and (max-width: 767px) {
                font-size: 18px;
              }
              &.number {
                font-size: 55px;
                line-height: 55px;
                font-weight: 600;
                padding-right: 5px;
              }
            }
          }
          .tip-step-content {
            padding: 0 20px;
            background: #f7fbfc;
            font-size: 16px;
            font-weight: 500;
            // display: none;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.4s ease;
            &.active {
              padding: 20px;
              padding-top: 0;
            }
          }
          ul {
            padding-left: 15px;
            padding-top: 15px;
          }
          p, ul {
            line-height: 24px;
          }
        }
        &.primary {
          .tip-step-box {
            background-color: $light-primary;
            border-radius: 25px;
            color: #fff;
            .tip-step-header {
              color: #fff;
            }
            @media screen and (max-width: 767px) {
              background-color: transparent;
              border-radius: 0;
              color: $primary;
              .tip-step-header {
                color: $primary;
              }
            }
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  .help {
    background-color: #235056;
    padding-top: 10px;
    margin-top: 30%;
    @media screen and (min-width: 1024px) {
      margin-top: 300px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
      padding-top: 0;
    }
    .help-wrapper {
      display: flex;
      align-items: center;
      padding: 30px 0;
      margin: 0 -20px;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
        padding: 10px 0;
      }
      .help-desc {
        width: 100%;
        padding: 30px 20px;
        color: #fff;
        font-size: 14px;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 20px;
        }
        .help-header {
          display: flex;
          position: relative;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            position: relative;
            flex-direction: column;
          }
          .help-header-img {
            margin-top: -30%;
            margin-bottom: 0;
            width: 40%;
            margin-left: auto;
            @media screen and (max-width: 767px) {
              margin-top: 0;
              margin: auto;
            }
            img {
              width: 100%;
            }
          }
        }
        .help-content {
          display: flex;
          @media screen and (max-width: 767px) {
            flex-direction: column;
          }
          .help-left-content {
            width: 50%;
            padding-right: 20px;
            @media screen and (max-width: 767px) {
              padding-bottom: 20px !important;
            }
          }
          .help-right-content {
            width: 50%;
            padding-left: 20px;
            a {
              margin: 0;
              background: #facc89;
              padding: 8px 16px;
              display: inline-block;
              border-radius: 50px;
              color: #0a383d;
              border: 1px solid transparent;
              transition: all 0.4s ease;
              text-decoration: none;
              font-weight: 600;
              font-size: 16px;
              &:hover {
                background-color: transparent;
                border: 1px solid #facc89;
                color: #facc89;
              }
            }
          }
          @media screen and (max-width: 767px) {
            .help-right-content, .help-left-content {
              width: 100%;
              padding: 0;
            }
          }
        }
        p {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 25px;
          font-weight: 500;
          &:last-child {
            margin-bottom: 0;
          }
        }
        h2 {
          font-weight: 700;
          font-size: 28px;
          width: calc(60% + 60px);
          position: absolute;
          line-height: 38px;
          bottom: 0;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 34px;
            position: relative;
          }
        }
      }
      .help-img {
        padding: 0 20px;
        width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        &.kr-img {
          max-width: 450px;
          margin: auto;
          display: block;
          @media screen and (max-width: 767px) {
            max-width: 300px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .finance {
    padding-top: 10px;
    color: #000;
    .finance-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 50px 0;
      margin: 0 -20px;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
        padding-bottom: 0;
        padding-top: 20px;
      }
      .finance-desc {
        width: 42%;
        padding: 30px 20px;
        font-size: 14px;
        margin-top: 20px;
        line-height: 24px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .cta-link {
          background: $secondary;
          color: #fff;
          text-decoration: none;
          padding: 6px 26px;
          font-weight: 600;
          border-radius: 50px;
          margin-top: 10px;
          display: inline-block;
          border: 2px solid transparent;
          transition: all 0.4s ease;
          &:hover {
            color: $secondary;
            border-color: $secondary;
            background: transparent;
          }
        }
        p {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 500;
          .bold {
            font-weight: 600;
          }
          a {
            color: $primary;
            text-decoration: none;
            font-weight: 600;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        h2 {
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 15px;
          line-height: 34px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
      .finance-img {
        padding: 0;
        width: 100%;
        // max-width: 1100px;
        margin: auto;
        @media screen and (max-width: 992px) {
          width: 100%;
          padding: 0 20px;
        }
        &.kr-img {
          max-width: 300px;
          margin: auto;
          display: block;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .book-now {
    background-color: #235056;
    padding-top: 10px;
    .book-wrapper {
      display: flex;
      align-items: center;
      padding: 100px 0;
      margin: 0;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        margin: 0;
        flex-direction: column;
        padding: 50px 0;
        text-align: center;
      }
      h2 {
        font-weight: 600;
        font-size: 28px;
        color: #fff;
        padding-right: 25px;
        line-height: 38px;
        @media screen and (max-width: 767px) {
          font-size: 24px;
          padding: 0 20px;
          padding-bottom: 10px;
          line-height: 34px;
        }
      }
      .cta-link {
        background: #facc89;
        color: $primary;
        text-decoration: none;
        padding: 10px 20px;
        font-weight: 700;
        border-radius: 50px;
        margin-top: 10px;
        display: inline-block;
        border: 2px solid transparent;
        transition: all 0.4s ease;
        white-space: nowrap;
        &:hover {
          color: #facc89;
          border-color: #facc89;
          background: transparent;
        }
      }
    }
  }
  footer {
    background-color: $dark-primary;
    padding: 20px 0;
    font-size: 14px;
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
    .footer-wrapper {
      color: #fff;
      .footer-heading {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: center;
        padding-bottom: 15px;
        padding-top: 30px;
        border-bottom: 1px solid #bdccd4;
        @media screen and (max-width: 767px) {
          padding-top: 10px;
        }
        .footer-socials {
          ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            li {
              padding: 0 10px;
              &:last-child {
                padding-right: 0;
              }
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                img {
                  max-width: 100%;
                  max-height: 30px;
                }
              }
            }
          }
        }
      }
      .bottom-footer {
        padding: 40px 0;
        text-align: center;
        @media screen and (max-width: 767px) {
          padding: 20px 0;
          padding-bottom: 0;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
        }
        a {
          color: #facc89;
          text-decoration: none;
          font-weight: 700;
          font-size: 28px;
          margin-top: 15px;
          display: inline-block;
        }
      }
      img {
        max-width: 110px;
      }
    }
  }
}
@keyframes slide {
  0% {
    top: -80px;
  }
  100% {
    top: 0px;
  }
}